import { ReactNode } from 'react'
import { HomeFilters } from 'home/constants/filter'
import { useFilters } from 'shared/hooks'
import FilterContext from './FilterContext'

const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [filters, setFilters] = useFilters<HomeFilters>(
    {
      filterBy: 'volume',
      period: '7-days',
      limit: 10,
    },
    { skipLimitStorage: true }
  )

  return (
    <FilterContext.Provider
      value={{
        filters,
        setFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export default FilterProvider
