import { Checkbox, Stack, Text } from '@gr4vy/poutine-react'
import { Form } from 'antd'
import { Copy } from 'shared/components/Copy'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { KountEdit } from './KountEdit'

const Content = () => {
  const form = Form.useFormInstance()
  const webhookUrl = form.getFieldValue(['webhookUrl'])

  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  return (
    <>
      <Stack gap={8}>
        <Text marginBottom="none">
          When a transaction is held in a manual review queue we will mark it as
          pending review and delay capture until we receive a webhook, or until
          it is manually captured or voided.
        </Text>
      </Stack>
      <FormItem
        name={['reviewsEnabled']}
        label={<Label>Review queue status</Label>}
        valuePropName="checked"
        trigger="onCheckedChange"
      >
        <Checkbox disabled={!hasWritePermission}>Enable review queue</Checkbox>
      </FormItem>
      <FormItem label={<Label>Review queue webhook URL</Label>}>
        <Copy text={webhookUrl} />
      </FormItem>
    </>
  )
}

export const KountManualReviews = () => {
  return (
    <KountEdit submitButtonText="Update merchant information">
      <Content />
    </KountEdit>
  )
}
