import {
  Tooltip,
  Flex,
  Button,
  Description,
  ButtonLink,
  Icon,
  Box,
} from '@gr4vy/poutine-react'
import { Row } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isReportCustomizable } from 'reports/helpers/report'
import { useUpdateReport } from 'reports/hooks/use-report'
import { useReportsPermission } from 'reports/hooks/use-reports-permission'
import { Report } from 'reports/services/report'
import {
  ColumnDef,
  DataTable,
  DateTime,
  DataTableProps,
} from 'shared/components/DataTable'
import { ModalRemove } from 'shared/components/Modal/ModalRemove'
import {
  formatOffset,
  getOffset,
  getTimezoneName,
} from 'shared/helpers/timezone'
import { pathTo } from 'shared/paths/reports'
import { QueryResult } from 'shared/services/client'
import { showSuccessMessage } from 'shared/utils/showMessage'
import { ReportFrequencyFilter } from '../ReportFrequencyFilter'
import styles from './ReportScheduleTable.module.scss'

export type ReportSchedulesTableProps = Pick<
  DataTableProps<Report>,
  'pagination' | 'columns'
> & { page: QueryResult<Report> }

export const ReportSchedulesTable = ({
  page,
  pagination,
}: ReportSchedulesTableProps) => {
  const { data, isLoading: loading } = page
  const { hasWritePermission } = useReportsPermission()
  const [removingId, setRemovingId] = useState<string | null>(null)
  const { mutate } = useUpdateReport()
  const handleDelete = (id: string) => {
    mutate(
      { id, scheduleEnabled: false },
      {
        onSuccess: () => {
          showSuccessMessage('Schedule deleted')
        },
      }
    )
  }

  const columns: Array<ColumnDef<Report>> = useMemo(
    () => [
      {
        accessorKey: 'name',
        size: 424,
        cell: function DatetimeCell({ getValue }) {
          const name = getValue<Report['name']>()
          const isFullyCustomizable = isReportCustomizable(name)
          return (
            <Flex gap={16} alignItems="center">
              <Description>
                <Description.Text>{name}</Description.Text>
                {isFullyCustomizable && (
                  <Description.SubText>Customized</Description.SubText>
                )}
              </Description>
            </Flex>
          )
        },
        header: 'Type',
      },
      {
        accessorKey: 'schedule',
        size: 200,
        cell: ({ getValue }) => {
          const schedule = getValue<Report['schedule']>()
          return capitalize(schedule)
        },
        header: () => (
          <Box className={styles.filter}>
            <ReportFrequencyFilter />
          </Box>
        ),
      },
      {
        accessorKey: 'scheduleTimezone',
        size: 248,
        cell: ({ getValue }) => {
          const timezone = getValue<Report['scheduleTimezone']>()
          const name = getTimezoneName(timezone)
          const offset = `UTC${formatOffset(getOffset(timezone))}`

          return (
            <Description>
              <Description.Text>{name}</Description.Text>
              <Description.SubText>{offset}</Description.SubText>
            </Description>
          )
        },
        header: 'Time zone',
      },
      {
        accessorKey: 'latestExecution',
        size: 192,
        cell: function DatetimeCell({ getValue }) {
          const execution = getValue<Report['latestExecution']>()
          return execution ? <DateTime value={execution.createdAt} /> : null
        },
        header: 'Last generated',
      },
      ...(hasWritePermission
        ? [
            {
              id: 'action',
              size: 76,
              cell: function ActionRow({ row }: { row: Row<Report> }) {
                const report = row.original
                return (
                  <Flex justifyContent="center">
                    <Tooltip content="Remove">
                      <Button
                        size="small"
                        variant="tertiary"
                        onClick={() => setRemovingId(report.id)}
                      >
                        <Icon name="trash-empty-md" />
                      </Button>
                    </Tooltip>
                  </Flex>
                )
              },
              header: 'Action',
            },
          ]
        : []),
      {
        id: 'details',
        size: 76,
        cell: function ActionRow({ row }) {
          const { merchantAccountId } = useParams() as {
            merchantAccountId: string
          }
          const report = row.original
          return (
            <Flex justifyContent="flex-end">
              <Tooltip content="Go to details">
                <ButtonLink
                  variant="tertiary"
                  size="small"
                  href={pathTo.reportScheduleDetails(
                    merchantAccountId,
                    report.id
                  )}
                />
              </Tooltip>
            </Flex>
          )
        },
        header: '',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      <DataTable
        data={data}
        loading={loading}
        columns={columns}
        pagination={pagination}
      />
      {removingId && (
        <ModalRemove
          title="Delete schedule"
          onCancel={() => setRemovingId(null)}
          onOk={() => {
            handleDelete(removingId)
            setRemovingId(null)
          }}
        >
          By performing this action the schedule will no longer generate
          reports. All reports previously generated by this schedule will remain
          in the system.
        </ModalRemove>
      )}
    </>
  )
}
