import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import { AccessLevel, Resource } from 'shared/permissions/constants'
import { useResourcePermission } from 'shared/permissions/hooks'

export type DragProvided = DraggableProvided
export type DragSnapshot = DraggableStateSnapshot

export const DragArea = ({
  id,
  index,
  children,
}: {
  id: string
  index: number
  children: any
}) => {
  const hasWritePermission = useResourcePermission(
    Resource.flows,
    AccessLevel.write
  )
  return (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
      isDragDisabled={!hasWritePermission}
    >
      {(
        dragProvided: DraggableProvided,
        dragSnapshot: DraggableStateSnapshot
      ) => {
        return children(
          dragProvided as DragProvided,
          dragSnapshot as DragSnapshot
        )
      }}
    </Draggable>
  )
}
