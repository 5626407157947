import { Text, TextLink, Divider } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { CredentialsChecker } from 'connections/components/CredentialsChecker'
import { validateAntiFraudService } from 'connections/services'
import { CredentialField } from 'shared/components/CredentialField/CredentialField'
import { Label } from 'shared/components/Form'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { SiftEdit } from './SiftEdit'

export const SiftCredentials = () => {
  const { id } = useParams() as { id: string }
  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  return (
    <SiftEdit submitButtonText="Update credentials">
      <Text margin="none">
        Learn how to configure Sift in{' '}
        <TextLink
          href="https://console.sift.com/developer/api-keys"
          opensInNewWindow
        >
          this quick start guide
        </TextLink>
        . We will help you find all the information you need to set up your
        anti-fraud service correctly.
      </Text>
      <CredentialField
        item={{ key: 'api_key', displayName: <Label>API key</Label> }}
        required
        secret
        group="fields"
      />
      <CredentialField
        item={{ key: 'beacon_key', displayName: <Label>Beacon key</Label> }}
        required
        secret
        group="fields"
      />
      <CredentialField
        item={{ key: 'account_id', displayName: <Label>Account ID</Label> }}
        required
        secret
        group="fields"
      />
      {hasWritePermission && (
        <CredentialsChecker
          fieldNames={[
            ['fields', 'api_key'],
            ['fields', 'account_id'],
          ]}
          verifyCredentials={validateAntiFraudService}
          antiFraudServiceDefinitionId="sift-anti-fraud"
          antiFraudServiceId={id}
        />
      )}
      <Divider />
    </SiftEdit>
  )
}

export default SiftCredentials
