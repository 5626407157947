import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { CustomReportDrawer } from 'reports/components/CustomReportDrawer'
import { TemplatedReportDrawer } from 'reports/components/TemplatedReportDrawer'
import { categories, reportTemplates } from 'reports/constants'
import { useReportsRoutes } from 'reports/hooks/use-reports-routes'
import { PageLayout } from 'shared/components/PageLayout'
import { AllReportsPage as View } from './AllReportsPage'

export const AllReportsPage = ({ title }: { title: string }) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const [template, setTemplate] = useState<{
    id: string
    isCustom: boolean
    schedule: boolean
  } | null>(null)
  const navigationTabs = useReportsRoutes(merchantAccountId)

  return (
    <PageLayout title={title} subNavigation={navigationTabs}>
      <View
        categories={categories}
        templates={reportTemplates}
        onReportClick={(id, isCustom, schedule) =>
          setTemplate({ id, isCustom, schedule })
        }
      />
      <TemplatedReportDrawer
        open={!!template && !!template.id && !template.isCustom}
        templateId={template && template.id}
        schedule={template?.schedule}
        onClose={() => setTemplate(null)}
      />
      <CustomReportDrawer
        open={!!template && !!template.id && template.isCustom}
        templateId={template && template.id}
        schedule={template?.schedule}
        onClose={() => setTemplate(null)}
      />
    </PageLayout>
  )
}
