import { Divider, Grid, GridItem, Stack } from '@gr4vy/poutine-react'
import { useContext } from 'react'
import { ConfiguredConnectionsTable } from 'home/components/ConfiguredConnectionsTable'
import { homeTransactionColumns } from 'home/constants/home-transaction-columns'
import FilterContext from 'home/contexts/FilterContext'
import { useConnections } from 'home/hooks/use-connections'
import { useTransactions } from 'home/hooks/use-transactions'
import {
  AccessLevel,
  Resource,
  useResourcePermissions,
} from 'shared/permissions'
import { TransactionFilters } from 'shared/services/transactions'
import TransactionTable from 'transactions/components/TransactionTable'

export const Tables = () => {
  const { filters, setFilters } = useContext(FilterContext)
  const [canReadPaymentServices, canReadConnections, canReadTransactions] =
    useResourcePermissions(
      [Resource.paymentServices, Resource.connections, Resource.transactions],
      AccessLevel.read
    )
  const connections = useConnections({
    filters,
    enabled: canReadPaymentServices && canReadConnections,
  })
  const transactions = useTransactions({
    filters,
    enabled: canReadTransactions,
  })

  const onFilter = (newFilters: TransactionFilters) => {
    setFilters({ ...filters, ...newFilters })
  }

  return canReadConnections || canReadTransactions ? (
    <Grid>
      {canReadTransactions && (
        <GridItem gridColumn={canReadConnections ? 'span 8' : 'span 12'}>
          <Divider width="md" borderColor="gray20" />
          <Stack gap={32}>
            <TransactionTable
              page={transactions}
              pagination={{ filters, onFilter, limits: [10, 20, 50, 100] }}
              columns={homeTransactionColumns}
            />
          </Stack>
        </GridItem>
      )}
      {canReadConnections && (
        <GridItem gridColumn={canReadTransactions ? 'span 4' : 'span 12'}>
          <Divider width="md" borderColor="gray20" />
          <ConfiguredConnectionsTable page={connections} />
        </GridItem>
      )}
    </Grid>
  ) : null
}
