import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  GET_BUYER_TRX,
  TRANSACTION,
} from 'shared/constants/query-keys/transactions'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import {
  showSuccessMessage,
  showProcessingMessage,
  showErrorMessage,
} from 'shared/utils/showMessage'
import { Transaction, Capture, captureTransaction } from 'transactions/services'

export type FormValues = Partial<Pick<Capture, 'amount'>>

export const useTransactionCapture = (
  transaction?: Transaction,
  callback?: () => void
) => {
  const queryClient = useQueryClient()
  const invalidateTransactions = async () => {
    await queryClient.invalidateQueries({ queryKey: [GET_BUYER_TRX] })
    await queryClient.invalidateQueries({
      queryKey: [TRANSACTION, transaction?.id],
    })
  }
  const captureMutation = useMutation({
    mutationFn: (values: FormValues) =>
      captureTransaction(transaction?.id as string, values),
    onSuccess: ({ status }) => {
      if (status === 'capture_succeeded') {
        showSuccessMessage('Transaction captured')
        invalidateTransactions()
      } else if (status === 'capture_pending') {
        showProcessingMessage('Transaction capture requested')
        invalidateTransactions()
      } else {
        showErrorMessage('Transaction capture failed')
      }
    },
    onError: handleErrorResponse,
    onSettled() {
      callback && callback()
    },
  })
  const onCapture = (values: FormValues) =>
    transaction?.id ? captureMutation.mutate(values) : () => {}

  return { onCapture, capturing: captureMutation.isPending }
}
