import { Stack, Heading, Grid, GridItem, Checkbox } from '@gr4vy/poutine-react'
import { Input } from 'antd'
import { snakeCase } from 'lodash'
import { useState } from 'react'
import { AddConnectionForm } from 'connections/components/AddConnectionForm/AddConnectionForm'
import { CredentialsChecker } from 'connections/components/CredentialsChecker'
import {
  AntiFraudService,
  validateAntiFraudService,
} from 'connections/services'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'

type FormValues = {
  displayName: string
  apiKey: string
  active: boolean
}

interface Props {
  saving: boolean
  onSubmit: (
    body: Omit<AntiFraudService, 'id' | 'type' | 'createdAt' | 'updatedAt'> & {
      fields: { key: string; value: string }[]
    }
  ) => void
  onCancel: () => void
}

export const Simulator = ({ saving, onSubmit, onCancel }: Props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    displayName: '',
    apiKey: '',
    active: false,
  })
  const handleSubmit = ({ active, displayName, ...fields }: FormValues) => {
    setInitialValues({ active, displayName, ...fields })
    onSubmit({
      active,
      displayName,
      antiFraudServiceDefinitionId: 'mock-anti-fraud',
      fields: Object.keys(fields)
        .map((key) => ({
          key: snakeCase(key),
          value: fields[key as keyof typeof fields],
        }))
        .filter((entry) => !!entry.value),
    })
  }

  return (
    <AddConnectionForm
      saving={saving}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      title="Add new anti-fraud connection"
    >
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={16}>
            <Stack gap={8}>
              <Heading level={4}>Credentials</Heading>
            </Stack>
            <Stack gap={24}>
              <FormItem
                name="apiKey"
                label={<Label>API key</Label>}
                required
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input />
              </FormItem>
              <CredentialsChecker
                fieldNames={['apiKey']}
                verifyCredentials={validateAntiFraudService}
                antiFraudServiceDefinitionId="mock-anti-fraud"
              />
            </Stack>
          </Stack>
        </GridItem>
      </Grid>
      <FormItem hidden={true} name="active">
        <Checkbox>Active</Checkbox>
      </FormItem>
    </AddConnectionForm>
  )
}
