import qs from 'qs'
import { Cursor } from 'shared/hooks'
import client, { CollectionResponse } from 'shared/services/client'
import { ReportExecution } from './report-execution'

export type Frequency = 'once' | 'daily' | 'monthly' | 'weekly'

export interface ReportPayload {
  name: string
  id?: string
  description?: string
  schedule?: Frequency
  scheduleEnabled?: boolean
  scheduleTimezone: string
  spec: {
    model: 'transactions' | 'transaction_retries'
    params: {
      fields?: string[] | null
      filters: Record<string, any> | null
      sort?: Record<string, string>[]
    }
  }
}

export interface Report {
  createdAt: string
  updatedAt: string
  description?: string
  schedule?: Frequency
  scheduleEnabled?: boolean
  scheduleTimezone: string
  type: 'report'
  id: string
  name: string
  latestExecution?: Omit<ReportExecution, 'report'>
}

export interface ReportsFilters {
  limit: number
  cursor: Cursor
  name?: string
  schedule?: Frequency | Frequency[]
  scheduleEnabled?: boolean
}

export const createReport = async (payload: ReportPayload): Promise<Report> => {
  const { data } = await client.post('/reports', payload)
  return data
}

export const updateReport = async ({
  id,
  ...payload
}: Partial<
  Pick<ReportPayload, 'id' | 'name' | 'description' | 'scheduleEnabled'>
>): Promise<Report> => {
  const { data } = await client.put(`/reports/${id}`, payload)
  return data
}

export const getReports = async (
  params: Partial<ReportsFilters> = {}
): Promise<CollectionResponse<Report>> => {
  const { data } = await client.get('/reports', {
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat', encode: false }),
  })
  return data
}

export const getReport = async (id: string): Promise<Report> => {
  const { data } = await client.get(`/reports/${id}`)
  return data
}
