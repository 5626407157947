import { Text, Stack, Flex, Button } from '@gr4vy/poutine-react'
import { useMemo } from 'react'
import {
  TransactionFilters,
  TransactionStatus,
} from 'shared/services/transactions'
import { QuickFilterStatus } from 'transactions/constants/quickFilters'
import { getToggledTransactionStatus } from 'transactions/helpers/get-toggled-status'
import { QuickFilterButton } from './Button'

export type QuickFiltersProps = {
  filters: Partial<TransactionFilters>
  onFilter: CallableFunction
}

const QuickFilters = ({ filters, onFilter }: QuickFiltersProps) => {
  const handleToggleQuickFilter = ({
    transactionStatus,
    hasRefunds,
    pendingReview,
  }: Partial<
    Record<
      keyof Pick<
        TransactionFilters,
        'transactionStatus' | 'hasRefunds' | 'pendingReview'
      >,
      TransactionStatus | boolean
    >
  >) => {
    onFilter({
      ...(transactionStatus && {
        transactionStatus: getToggledTransactionStatus(
          transactionStatus as TransactionStatus,
          filters?.transactionStatus
        ),
      }),
      ...(hasRefunds && {
        hasRefunds: filters?.hasRefunds ? undefined : hasRefunds,
      }),
      ...(pendingReview && {
        pendingReview: filters?.pendingReview ? undefined : pendingReview,
      }),
    })
  }

  const canClear = useMemo(() => {
    if (
      !filters?.transactionStatus &&
      !filters?.hasRefunds &&
      !filters?.pendingReview
    ) {
      return false
    }

    return (
      filters.hasRefunds ||
      filters.pendingReview ||
      [filters.transactionStatus]
        .flat()
        .find((status) =>
          [
            QuickFilterStatus.SUCCEEDED,
            QuickFilterStatus.DECLINED,
            QuickFilterStatus.FAILED,
            QuickFilterStatus.VOIDED,
          ].includes(status as QuickFilterStatus)
        )
    )
  }, [filters?.hasRefunds, filters?.pendingReview, filters.transactionStatus])

  const handleClear = () => {
    const filteredStatuses = [filters.transactionStatus]
      .flat()
      .filter(
        (status) =>
          ![
            QuickFilterStatus.SUCCEEDED,
            QuickFilterStatus.DECLINED,
            QuickFilterStatus.FAILED,
            QuickFilterStatus.VOIDED,
          ].includes(status as QuickFilterStatus)
      )

    onFilter({
      ...(filters.transactionStatus && {
        transactionStatus: !!filteredStatuses.length
          ? filteredStatuses
          : undefined,
      }),
      ...(filters.hasRefunds && {
        hasRefunds: undefined,
      }),
      ...(filters.pendingReview && {
        pendingReview: undefined,
      }),
    })
  }

  return (
    <Stack gap={8}>
      <Text margin="none" fontWeight="medium" style={{ pointerEvents: 'none' }}>
        Quick filters
      </Text>
      <Flex alignItems="center" gap={16}>
        <QuickFilterButton
          onClick={() =>
            handleToggleQuickFilter({
              transactionStatus: QuickFilterStatus.SUCCEEDED,
            })
          }
          data-selected={
            !!filters?.transactionStatus?.includes(QuickFilterStatus.SUCCEEDED)
          }
        >
          Captured
        </QuickFilterButton>
        <QuickFilterButton
          onClick={() =>
            handleToggleQuickFilter({
              transactionStatus: QuickFilterStatus.DECLINED,
            })
          }
          data-selected={
            !!filters?.transactionStatus?.includes(QuickFilterStatus.DECLINED)
          }
        >
          Declined
        </QuickFilterButton>
        <QuickFilterButton
          onClick={() =>
            handleToggleQuickFilter({
              transactionStatus: QuickFilterStatus.FAILED,
            })
          }
          data-selected={
            !!filters?.transactionStatus?.includes(QuickFilterStatus.FAILED)
          }
        >
          Failed
        </QuickFilterButton>
        <QuickFilterButton
          onClick={() =>
            handleToggleQuickFilter({
              transactionStatus: QuickFilterStatus.VOIDED,
            })
          }
          data-selected={
            !!filters?.transactionStatus?.includes(QuickFilterStatus.VOIDED)
          }
        >
          Voided
        </QuickFilterButton>
        <QuickFilterButton
          onClick={() =>
            handleToggleQuickFilter({
              hasRefunds: true,
            })
          }
          data-selected={filters?.hasRefunds}
        >
          Refunded
        </QuickFilterButton>
        <QuickFilterButton
          onClick={() =>
            handleToggleQuickFilter({
              pendingReview: true,
            })
          }
          data-selected={filters?.pendingReview}
        >
          In review
        </QuickFilterButton>
        {canClear && (
          <Button onClick={handleClear} variant="tertiary" size="small">
            Clear
          </Button>
        )}
      </Flex>
    </Stack>
  )
}

export default QuickFilters
