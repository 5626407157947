import { atoms, Text } from '@gr4vy/poutine-react'
import { Input, Select } from 'antd'
import isoCountries from 'iso-3166-1'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { fqdnValidator } from 'shared/utils/validators'
import { SiftEdit } from './SiftEdit'

const countryOptions = isoCountries.all().map((value) => ({
  label: value.country,
  value: value.alpha2,
}))

export const SiftMerchantInfo = () => {
  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  return (
    <SiftEdit submitButtonText="Update merchant information">
      <Text marginBottom="none">
        These default merchant identifiers help Sift identify the right
        transaction with the right site.
      </Text>
      <FormItem
        name={['fields', 'site_country']}
        label={<Label optional>Site country</Label>}
        extra={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            Country the company is providing service from. Use ISO-3166 country
            code.
          </Text>
        }
        normalize={(value) => (value === undefined ? '' : value)}
      >
        <Select
          allowClear
          disabled={!hasWritePermission}
          showSearch
          placeholder="Select a country"
          options={countryOptions}
          filterOption={(input, option) =>
            `${option?.label}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
      </FormItem>
      <FormItem
        name={['fields', 'site_domain']}
        label={<Label optional>Site domain</Label>}
        extra={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            Domain being interfaced with. Use fully qualified domain name.
          </Text>
        }
        rules={[{ validator: fqdnValidator }]}
      >
        <Input disabled={!hasWritePermission} />
      </FormItem>
      <FormItem
        name={['fields', 'merchant_id']}
        label={<Label optional>Merchant ID</Label>}
        extra={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            The internal identifier for the merchant or seller providing the
            good or service (e.g. AX527123).
          </Text>
        }
      >
        <Input disabled={!hasWritePermission} />
      </FormItem>
      <FormItem
        name={['fields', 'merchant_name']}
        label={<Label optional>Merchant name</Label>}
        extra={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            The name of the merchant or seller providing the good or service.
          </Text>
        }
      >
        <Input disabled={!hasWritePermission} />
      </FormItem>
      <FormItem
        name={['fields', 'merchant_category_code']}
        label={<Label optional>Merchant category code</Label>}
        rules={[
          {
            type: 'number',
            message: 'Must contain digits only',
            transform(value) {
              return value && Number(value)
            },
          },
          { len: 4, message: 'Must contain 4 digits only' },
        ]}
        extra={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            The merchant category code follows the 4-digit ISO code. Use
            ISO-18245 MCC ISO Merchant Category Code.
          </Text>
        }
      >
        <Input disabled={!hasWritePermission} />
      </FormItem>
    </SiftEdit>
  )
}

export default SiftMerchantInfo
