import { atoms, Button, Stack } from '@gr4vy/poutine-react'
import { Form } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { ReactNode, SyntheticEvent } from 'react'
import { useParams } from 'react-router-dom'
import { FormItem } from 'shared/components/FormItem'
import { FormPromptUnsavedChanges } from 'shared/components/FormPromptUnsavedChanges'
import { PageLayout } from 'shared/components/PageLayout'
import { PageTitleInput } from 'shared/components/PageTitleInput'
import { pathTo } from 'shared/paths/connections'

interface Props<T> {
  saving: boolean
  initialValues: T
  handleSubmit: (form: T) => void
  onCancel: () => void
  title: string
  children: ReactNode
}

export const AddConnectionForm = <T extends Store>({
  saving,
  initialValues,
  handleSubmit,
  onCancel,
  title,
  children,
}: Props<T>) => {
  const [form] = Form.useForm()
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const breadcrumbs = (title: string) => [
    { title: 'Catalog', url: pathTo.connectionsCatalog(merchantAccountId) },
    { title: title },
  ]
  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault()
    onCancel()
  }

  return (
    <Form
      name="rule"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      autoComplete="off"
      layout={'vertical'}
      requiredMark={false}
      scrollToFirstError={{
        block: 'center',
      }}
    >
      <PageLayout
        breadcrumbs={breadcrumbs(title)}
        title={title}
        titleChildren={
          <FormItem
            className={atoms({ fontWeight: 'normal', width: 'full' })}
            name="displayName"
            rules={[
              {
                required: true,
                message: 'Please add a connection name',
              },
            ]}
          >
            <PageTitleInput
              data-testid="connection-name-input"
              placeholder="Type connection name"
            />
          </FormItem>
        }
      >
        <Stack paddingTop={8} gap={40}>
          {children}
          <Stack direction="row" gap={16}>
            <Button
              loading={saving}
              loadingText="Saving connection"
              variant="primary"
            >
              Save connection
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Stack>
        </Stack>
        <FormPromptUnsavedChanges form={form} initialValues={initialValues} />
      </PageLayout>
    </Form>
  )
}
