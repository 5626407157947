import { atoms, Checkbox, Text } from '@gr4vy/poutine-react'
import { Input } from 'antd'
import { EditGiftCardServiceForm } from 'connections/components/EditGiftCardServiceForm/EditGiftCardServiceForm'
import { useGiftCardServiceAndDefinition } from 'connections/hooks/use-gift-card-service-and-definition'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { Loading } from 'shared/components/Loading'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'

const GiftCardServiceOverview = () => {
  const { isLoading } = useGiftCardServiceAndDefinition()
  const hasPermission = useResourcePermission(
    Resource.giftCardServices,
    AccessLevel.write
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <Text margin="none">
        Set a display name for this connection, in order to find it easily
        within the dashboard. If it&rsquo;s deactivated, any flows rules using
        it will no longer be processed.
      </Text>
      <FormItem
        name={['displayName']}
        label={<Label>Display name</Label>}
        rules={[{ required: true, message: 'This field is required' }]}
        style={{ margin: 0 }}
      >
        <Input disabled={!hasPermission} />
      </FormItem>
      <FormItem
        name={['active']}
        label={<Label>Connection status</Label>}
        valuePropName="checked"
        trigger="onCheckedChange"
        shouldUpdate
        help={
          <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
            When disabled this connection will no longer be used to process
            transactions.
          </Text>
        }
      >
        <Checkbox disabled={!hasPermission}>Active</Checkbox>
      </FormItem>
    </>
  )
}

export const GiftCardServiceOverviewPage = () => (
  <EditGiftCardServiceForm submitButtonText="Update details">
    <GiftCardServiceOverview />
  </EditGiftCardServiceForm>
)
