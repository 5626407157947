import { Grid, GridItem, Stack, Text } from '@gr4vy/poutine-react'
import { FormInstance, Input } from 'antd'
import clsx from 'clsx'
import React, { useContext } from 'react'
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { ControlLink } from 'flows/components/ControlLink/ControlLink'
import {
  DragArea,
  DragProvided,
  DragSnapshot,
} from 'flows/components/DragAndDrop'
import { OutcomeAction, OutcomeMessage } from 'flows/components/OutcomeAction'
import {
  ActionOutcome,
  RuleConfigOutcome,
  RuleOutcomeConfig,
  ruleOutcomeConfig,
} from 'flows/constants'
import { FlowContext } from 'flows/contexts/FlowContext'
import { useRuleOutcomes } from 'flows/hooks/useRuleOutcomes'
import { componentConfigSelector } from 'flows/utils/componentConfigSelector'
import { FormItem } from 'shared/components/FormItem'
import { Action } from 'shared/constants'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import styles from './OutcomeList.module.scss'

const RuleOutcomesSection = ({
  id,
  action,
  outcomes,
  isHidden,
  form,
  children,
}: {
  id: string
  action: Action
  outcomes: ActionOutcome[]
  isHidden: boolean
  form: FormInstance
  children?: React.ReactNode
}) => (
  <Droppable droppableId={id}>
    {(
      dropProvided: DroppableProvided,
      dropSnapshot: DroppableStateSnapshot
    ) => {
      const classNames = clsx({
        [styles.draggingOver]: dropSnapshot.isDraggingOver,
      })
      return (
        <div
          ref={dropProvided.innerRef}
          {...dropProvided.droppableProps}
          data-dropping={dropSnapshot.isDraggingOver}
        >
          <Stack direction="column" gap="none" className={classNames}>
            {children}
            {outcomes.map((outcome, index) => (
              <DragArea id={outcome.id} key={outcome.id} index={index}>
                {(dragProvided: DragProvided, dragSnapshot: DragSnapshot) => (
                  <FormItem shouldUpdate noStyle>
                    {() => (
                      <OutcomeAction
                        key={outcome.id}
                        action={action}
                        outcome={outcome}
                        dragProvided={dragProvided}
                        dragSnapshot={dragSnapshot}
                        isHidden={isHidden}
                        form={form}
                      />
                    )}
                  </FormItem>
                )}
              </DragArea>
            ))}
            {dropProvided.placeholder}
          </Stack>
        </div>
      )
    }}
  </Droppable>
)

export const OutcomeList = ({
  form,
  outcomes,
  outcomeConfig,
}: {
  form: FormInstance
  outcomes: ActionOutcome[]
  outcomeConfig: RuleConfigOutcome
}) => {
  const hasWriteAccess = useResourcePermission(
    Resource.flows,
    AccessLevel.write
  )
  const { flow, action } = useContext(FlowContext)

  const { categorisedOutcomes, onMoveRule, onMoveAll } = useRuleOutcomes(
    form,
    outcomes
  )

  const config = componentConfigSelector<RuleOutcomeConfig>(
    flow,
    action,
    ruleOutcomeConfig
  )

  const { visibleOutcomes, hiddenOutcomes } = categorisedOutcomes
  const totalOutcomes = visibleOutcomes.length + hiddenOutcomes.length

  return (
    <Grid>
      <GridItem gridColumn="span 6">
        <FormItem name={['outcome', 'type']} hidden>
          <Input />
        </FormItem>
        <DragDropContext onDragEnd={onMoveRule?.mutate}>
          <Stack direction="column" gap={8}>
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={16}
              >
                <Text variant="overline1" as="div">
                  {config?.selectedLabel}
                </Text>
                {!config?.hideControls && hasWriteAccess && (
                  <Stack direction="row" gap={24}>
                    <ControlLink
                      label="Select All"
                      disabled={visibleOutcomes.length === totalOutcomes}
                      onMoveAll={onMoveAll.mutate}
                      from="hiddenOutcomes"
                      to="visibleOutcomes"
                    />
                    <ControlLink
                      label="Unselect All"
                      disabled={hiddenOutcomes.length === totalOutcomes}
                      onMoveAll={onMoveAll.mutate}
                      from="visibleOutcomes"
                      to="hiddenOutcomes"
                    />
                  </Stack>
                )}
              </Stack>
              <RuleOutcomesSection
                id="visibleOutcomes"
                outcomes={visibleOutcomes}
                isHidden={false}
                form={form}
                action={action}
              >
                {hasWriteAccess && !visibleOutcomes.length && (
                  <OutcomeMessage>{config?.selectedPlaceholder}</OutcomeMessage>
                )}
              </RuleOutcomesSection>
              <FormItem
                className={styles.hiddenInput}
                name={['outcome', 'result']}
                rules={[
                  {
                    required: true,
                    message: config?.emptyErrorMessage,
                  },
                  () => ({
                    validator(_, value: string) {
                      if (
                        outcomeConfig.max &&
                        value.length > outcomeConfig.max
                      ) {
                        return Promise.reject(
                          new Error(
                            `Only ${outcomeConfig.max} connection can be used with this type of rule.`
                          )
                        )
                      }

                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input
                  style={{
                    minHeight: 0,
                    height: 0,
                    padding: 0,
                    margin: 0,
                    border: 0,
                    visibility: 'hidden',
                  }}
                  data-testid="rule_outcome_result"
                />
              </FormItem>
            </>
            <>
              <Text variant="overline1" as="div" paddingTop={16}>
                {config?.unselectedLabel}
              </Text>
              <RuleOutcomesSection
                id="hiddenOutcomes"
                outcomes={hiddenOutcomes}
                isHidden
                form={form}
                action={action}
              >
                {hasWriteAccess && !hiddenOutcomes.length && (
                  <OutcomeMessage>
                    {config?.unselectedPlaceholder}
                  </OutcomeMessage>
                )}
              </RuleOutcomesSection>
            </>
          </Stack>
        </DragDropContext>
      </GridItem>
    </Grid>
  )
}
