import {
  Stack,
  Text,
  TextLink,
  Heading,
  Grid,
  GridItem,
  atoms,
  Checkbox,
} from '@gr4vy/poutine-react'
import { Input, Select } from 'antd'
import isoCountries from 'iso-3166-1'
import { snakeCase } from 'lodash'
import { useState } from 'react'
import { AddConnectionForm } from 'connections/components/AddConnectionForm/AddConnectionForm'
import { CredentialsChecker } from 'connections/components/CredentialsChecker'
import {
  AntiFraudService,
  validateAntiFraudService,
} from 'connections/services'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { fqdnValidator } from 'shared/utils/validators'

type FormValues = {
  displayName: string
  apiKey: string
  beaconKey: string
  accountId: string
  approveDecision: string
  declineDecision: string
  merchantId: string
  merchantName: string
  merchantCategoryCode: string
  active: boolean
}

interface Props {
  saving: boolean
  onSubmit: (
    body: Omit<AntiFraudService, 'id' | 'type' | 'createdAt' | 'updatedAt'> & {
      fields: { key: string; value: string }[]
    }
  ) => void
  onCancel: () => void
}

const countryOptions = isoCountries.all().map((value) => ({
  label: value.country,
  value: value.alpha2,
}))

export const Sift = ({ saving, onSubmit, onCancel }: Props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    displayName: '',
    apiKey: '',
    beaconKey: '',
    accountId: '',
    approveDecision: '',
    declineDecision: '',
    merchantId: '',
    merchantName: '',
    merchantCategoryCode: '',
    active: false,
  })
  const handleSubmit = ({ active, displayName, ...fields }: FormValues) => {
    setInitialValues({ active, displayName, ...fields })
    onSubmit({
      active,
      displayName,
      antiFraudServiceDefinitionId: 'sift-anti-fraud',
      fields: Object.keys(fields)
        .map((key) => ({
          key: snakeCase(key),
          value: fields[key as keyof typeof fields],
        }))
        .filter((entry) => !!entry.value),
    })
  }

  return (
    <AddConnectionForm
      saving={saving}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      title="Add new anti-fraud connection"
    >
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={16}>
            <Stack gap={8}>
              <Heading level={4}>Credentials</Heading>
              <Text>
                Learn how to configure Sift in{' '}
                <TextLink
                  href="https://console.sift.com/developer/api-keys"
                  opensInNewWindow
                >
                  this quick start guide
                </TextLink>
                . We will help you find all the information you need to set up
                your anti-fraud service correctly.
              </Text>
            </Stack>
            <Stack gap={24}>
              <FormItem
                name="apiKey"
                label={<Label>API key</Label>}
                required
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input />
              </FormItem>
              <FormItem
                name="beaconKey"
                label={<Label>Beacon key</Label>}
                required
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input />
              </FormItem>
              <FormItem
                name="accountId"
                label={<Label>Account ID</Label>}
                required
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input />
              </FormItem>
              <CredentialsChecker
                fieldNames={['apiKey', 'accountId']}
                verifyCredentials={validateAntiFraudService}
                antiFraudServiceDefinitionId="sift-anti-fraud"
              />
            </Stack>
          </Stack>
        </GridItem>
      </Grid>
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={8}>
            <Heading level={4}>Merchant information</Heading>
            <Text>
              These default merchant identifiers help Sift identify the right
              transaction with the right site.
            </Text>
            <Stack gap={16}>
              <FormItem
                name="siteCountry"
                label={<Label optional>Site country</Label>}
                extra={
                  <Text
                    as="span"
                    className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                  >
                    Country the company is providing service from. Use ISO-3166
                    country code.
                  </Text>
                }
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select a country"
                  options={countryOptions}
                  filterOption={(input, option) =>
                    `${option?.label}`
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                />
              </FormItem>
              <FormItem
                name="siteDomain"
                label={<Label optional>Site domain</Label>}
                extra={
                  <Text
                    as="span"
                    className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                  >
                    Domain being interfaced with. Use fully qualified domain
                    name.
                  </Text>
                }
                rules={[{ validator: fqdnValidator }]}
              >
                <Input />
              </FormItem>
              <FormItem
                name="merchantId"
                label={<Label optional>Merchant ID</Label>}
                extra={
                  <Text
                    as="span"
                    className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                  >
                    The internal identifier for the merchant or seller providing
                    the good or service (e.g. AX527123).
                  </Text>
                }
              >
                <Input />
              </FormItem>
              <FormItem
                name="merchantName"
                label={<Label optional>Merchant name</Label>}
                extra={
                  <Text
                    as="span"
                    className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                  >
                    The name of the merchant or seller providing the good or
                    service.
                  </Text>
                }
              >
                <Input />
              </FormItem>
              <FormItem
                name="merchantCategoryCode"
                label={<Label optional>Merchant category code</Label>}
                rules={[
                  {
                    type: 'number',
                    message: 'Must contain digits only',
                    transform(value) {
                      return Number(value)
                    },
                  },
                  { len: 4, message: 'Must contain 4 digits only' },
                ]}
                extra={
                  <Text
                    as="span"
                    className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                  >
                    The merchant category code follows the 4-digit ISO code. Use
                    ISO-18245 MCC ISO Merchant Category Code.
                  </Text>
                }
              >
                <Input />
              </FormItem>
            </Stack>
          </Stack>
        </GridItem>
      </Grid>
      <FormItem hidden={true} name="active">
        <Checkbox>Active</Checkbox>
      </FormItem>
    </AddConnectionForm>
  )
}
