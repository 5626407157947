import {
  Stack,
  Text,
  Heading,
  Grid,
  GridItem,
  Checkbox,
  TextLink,
  atoms,
} from '@gr4vy/poutine-react'
import { Input } from 'antd'
import { snakeCase } from 'lodash'
import { useState } from 'react'
import { AddConnectionForm } from 'connections/components/AddConnectionForm/AddConnectionForm'
import { CredentialsChecker } from 'connections/components/CredentialsChecker'
import {
  AntiFraudService,
  validateAntiFraudService,
} from 'connections/services'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'

type FormValues = {
  displayName: string
  siteId: string
  secretKey: string
  merchantId: string
  merchantDomain: string
  merchantName: string
  active: boolean
}

interface Props {
  saving: boolean
  onSubmit: (
    body: Omit<AntiFraudService, 'id' | 'type' | 'createdAt' | 'updatedAt'> & {
      fields: { key: string; value: string }[]
    }
  ) => void
  onCancel: () => void
}

export const Forter = ({ saving, onSubmit, onCancel }: Props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    displayName: '',
    siteId: '',
    secretKey: '',
    merchantId: '',
    merchantDomain: '',
    merchantName: '',
    active: false,
  })
  const handleSubmit = ({ active, displayName, ...fields }: FormValues) => {
    setInitialValues({ active, displayName, ...fields })
    onSubmit({
      active,
      displayName,
      antiFraudServiceDefinitionId: 'forter-anti-fraud',
      fields: Object.keys(fields)
        .map((key) => ({
          key: snakeCase(key),
          value: fields[key as keyof typeof fields],
        }))
        .filter((entry) => !!entry.value),
    })
  }

  return (
    <AddConnectionForm
      saving={saving}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      title="Add new anti-fraud connection"
    >
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={24}>
            <FormItem
              name="siteId"
              label={<Label>Site ID</Label>}
              required
              extra={
                <Text
                  as="span"
                  className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                >
                  The{' '}
                  <TextLink
                    href="https://portal.forter.com/app/psp-experience/settings/general"
                    opensInNewWindow
                  >
                    unique ID for your site
                  </TextLink>{' '}
                  used to connect to the Forter API.
                </Text>
              }
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input />
            </FormItem>
            <FormItem
              name="secretKey"
              label={<Label>Secret Key</Label>}
              required
              rules={[{ required: true, message: 'This field is required' }]}
              extra={
                <Text
                  as="span"
                  className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                >
                  The{' '}
                  <TextLink
                    href="https://portal.forter.com/app/psp-experience/settings/general"
                    opensInNewWindow
                  >
                    secret API key
                  </TextLink>{' '}
                  used to connect to the Forter API.
                </Text>
              }
            >
              <Input />
            </FormItem>
            <CredentialsChecker
              fieldNames={['siteId', 'secretKey']}
              verifyCredentials={validateAntiFraudService}
              antiFraudServiceDefinitionId="forter-anti-fraud"
            />
          </Stack>
        </GridItem>
      </Grid>
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={8}>
            <Heading level={4}>Merchant information</Heading>
            <Text>
              These default{' '}
              <TextLink
                href="https://portal.forter.com/app/developer/api/api/data-objects/MerchantIdentifiers"
                opensInNewWindow
              >
                merchant identifiers
              </TextLink>{' '}
              help Forter identify the right transaction with the right site.
            </Text>
            <Stack gap={16}>
              <FormItem
                name="merchantId"
                label={<Label optional>Merchant ID</Label>}
                extra={
                  <Text
                    as="span"
                    className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                  >
                    The unique ID for of your Forter merchant account.
                  </Text>
                }
              >
                <Input />
              </FormItem>
              <FormItem
                name="merchantDomain"
                label={<Label optional>Merchant domain</Label>}
                extra={
                  <Text
                    as="span"
                    className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                  >
                    The host name of the site for which to get a decision.
                  </Text>
                }
              >
                <Input />
              </FormItem>
              <FormItem
                name="merchantName"
                label={<Label optional>Merchant Name</Label>}
                extra={
                  <Text
                    as="span"
                    className={atoms({ fontSize: 'xs', paddingTop: 4 })}
                  >
                    The brand name of the site for which to get a decision.
                  </Text>
                }
              >
                <Input />
              </FormItem>
            </Stack>
          </Stack>
        </GridItem>
      </Grid>
      <FormItem hidden={true} name="active">
        <Checkbox>Active</Checkbox>
      </FormItem>
    </AddConnectionForm>
  )
}
