import { size } from 'lodash'
import { ReportPayload } from 'reports/services/report'
export type ReportTemplate = {
  id: string
  title: string
  description: string
  category: Category
  isCustom: boolean
  spec: (params: Record<string, any>) => ReportPayload['spec']
}

export enum Category {
  custom = 'Custom',
  financial = 'Financial',
  analytical = 'Analytical',
}

export enum Reports {
  all = 'all',
  scheduled = 'scheduled',
  generated = 'generated',
}

export const reportsNames: Record<Reports, string> = {
  [Reports.all]: 'All Reports',
  [Reports.scheduled]: 'Scheduled',
  [Reports.generated]: 'Generated',
}

export const categories = [
  Category.custom,
  Category.financial,
  Category.analytical,
]

export const reportTemplates: ReportTemplate[] = [
  {
    id: 'customizable',
    title: 'Customized',
    description:
      'Allows you to generate fully customizable reports on transaction level data.',
    category: Category.custom,
    isCustom: true,
    spec: ({
      status,
      from,
      to,
      method,
      scheme,
      currency,
      threeDSecureStatus,
      threeDSecureEci,
      threeDSecureAuthResp,
      metadata,
    }) => ({
      model: 'transactions',
      params: {
        fields: null,
        filters: {
          status,
          ...(from && to ? { createdAt: { start: from, end: to } } : {}),
          method,
          scheme,
          currency,
          threeDSecureStatus,
          threeDSecureEci,
          threeDSecureAuthResp,
          metadata,
        },
        sort: [
          {
            field: 'created_at',
            order: 'desc',
          },
        ],
      },
    }),
  },
  {
    id: 'accounts_receivable',
    title: 'Receivable transactions',
    description:
      'Reports on open receivables. This report can be used to be matched against settlement reports for full reconciliation.',
    category: Category.financial,
    isCustom: false,
    spec: ({ from, to }) => ({
      model: 'transactions',
      params: {
        fields: null,
        filters: {
          status: ['capture_succeeded'],
          ...(from && to ? { capturedAt: { start: from, end: to } } : {}),
        },
        sort: [
          {
            field: 'captured_at',
            order: 'desc',
          },
        ],
      },
    }),
  },
  {
    id: 'created_payments',
    title: 'All transactions',
    description:
      'Provides an overview of all authorised, captured, declined and failed transactions.',
    category: Category.financial,
    isCustom: false,
    spec: ({ from, to }) => {
      const filters = {
        ...(from && to ? { createdAt: { start: from, end: to } } : {}),
      }

      return {
        model: 'transactions',
        params: {
          fields: null,
          filters: size(filters) ? filters : null,
          sort: [
            {
              field: 'created_at',
              order: 'desc',
            },
          ],
        },
      }
    },
  },
  {
    id: 'renewals',
    title: 'Renewals',
    description:
      'Reports on all subsequent recurring transactions and installments.',
    category: Category.financial,
    isCustom: false,
    spec: ({ from, to }) => {
      const filters = {
        ...(from && to ? { createdAt: { start: from, end: to } } : {}),
        paymentSource: ['recurring', 'installment'],
        isSubsequentPayment: true,
        merchantInitiated: true,
      }

      return {
        model: 'transactions',
        params: {
          fields: null,
          filters: size(filters) ? filters : null,
          sort: [
            {
              field: 'created_at',
              order: 'desc',
            },
          ],
        },
      }
    },
  },
  {
    id: 'declined_payments',
    title: 'Declined transactions',
    description:
      'Reports on all declined transactions and includes the raw acquirer response.',
    category: Category.analytical,
    isCustom: false,
    spec: ({ from, to }) => ({
      model: 'transactions',
      params: {
        fields: null,
        filters: {
          status: ['authorization_declined'],
          ...(from && to ? { createdAt: { start: from, end: to } } : {}),
        },
        sort: [
          {
            field: 'created_at',
            order: 'desc',
          },
        ],
      },
    }),
  },
  {
    id: 'retry',
    title: 'Retries',
    description:
      'This report contains transaction level information on retry events, including retry routing and raw acquirer responses.',
    category: Category.analytical,
    isCustom: false,
    spec: ({ from, to }) => ({
      model: 'transaction_retries',
      params: {
        filters: {
          ...(from && to ? { createdAt: { start: from, end: to } } : {}),
        },
      },
    }),
  },
]
