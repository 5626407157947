import { Box, Flex, Container, TextLink } from '@gr4vy/poutine-react'
import { useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { SettingsMenu, SupportMenu, UserMenu } from 'App/components/Menu'
import { MerchantAccountDropdown } from 'App/components/MerchantAccountDropdown'
import MerchantContext from 'App/contexts/MerchantContext'
import { pathTo as adminPathTo } from 'admin/paths'
import {
  merchantLogo,
  backgroundColor,
  merchantLogoFooterPosition,
} from 'shared/constants/merchant'
import { getEntityPathFromUrl } from 'shared/helpers/routes'
import { useUserHomepage } from 'shared/permissions'
import { useMerchantAccounts } from 'shared/permissions/hooks/use-merchant-accounts'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'
import styles from './TopBar.module.scss'
import gr4vyWordmark from './gr4vy-wordmark.svg'
import poweredBy from './powered-by.svg'

const logo = merchantLogo
  ? `/img/merchant-logos/${merchantLogo}`
  : gr4vyWordmark

export const TopBar = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { merchantAccounts } = useContext(MerchantContext)
  const { merchantAccountId, isAdminMode, canAccessAdminMode } =
    useMultiMerchant()
  const { hasMultipleMerchantAccounts } = useMerchantAccounts()
  const userHomepage = useUserHomepage(merchantAccountId)
  const onChangeMerchantAccountId = (newMerchantAccountId: string) => {
    // clear react-query cache to avoid issues showing wrong merchant account data
    queryClient.clear()
    const entityPath = getEntityPathFromUrl()
    navigate(`/merchants/${newMerchantAccountId}/${entityPath ?? ''}`)
  }

  return (
    <Flex justifyContent="center" style={{ backgroundColor }}>
      <Container>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          color="white"
          height={64}
          as="nav"
          aria-label="top"
          className={styles.noWrap}
        >
          <Flex
            alignItems="center"
            gap={merchantLogo ? 24 : 16}
            className={styles.logoContainer}
          >
            <Flex alignItems="center" gap={16}>
              <TextLink href={userHomepage}>
                <Box height={merchantLogo ? 32 : 20} margin={8} asChild>
                  <img src={logo} alt="Logo" />
                </Box>
              </TextLink>
              {merchantLogo && !merchantLogoFooterPosition && (
                <Box>
                  <img src={poweredBy} alt="Powered by Gr4vy" width={54} />
                </Box>
              )}
            </Flex>
            {hasMultipleMerchantAccounts && (
              <Flex alignItems="center" gap={8}>
                <MerchantAccountDropdown
                  onManageClick={() => navigate(adminPathTo.admin)}
                  onChange={onChangeMerchantAccountId}
                  merchantAccounts={merchantAccounts}
                  active={!isAdminMode}
                  value={merchantAccountId}
                />
              </Flex>
            )}
          </Flex>
          <Flex gap={16} alignItems="center">
            <SupportMenu />
            {canAccessAdminMode && <SettingsMenu />}
            <UserMenu />
          </Flex>
        </Flex>
      </Container>
    </Flex>
  )
}
