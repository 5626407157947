/* eslint-disable camelcase */
import {
  ColorAmber60,
  ColorBlue60,
  ColorGray70,
  ColorGreen60,
  ColorRed60,
} from '@gr4vy/poutine-tokens/js/tokens'
import { ApplePayCertificate } from 'connections/services/applePayCertificates'
import { MerchantProfileSchemeStatus } from 'connections/services/paymentServices'
import { PaymentMethod } from 'payment-methods/services/paymentMethods'
import { RefundStatus } from 'refunds/services'
import { TransactionStatus } from 'shared/services/transactions'
import { GiftCardRedemption } from 'transactions/services'

export const statusesMap: Record<
  | TransactionStatus
  | PaymentMethod['status']
  | ApplePayCertificate['status']
  | GiftCardRedemption['status']
  | MerchantProfileSchemeStatus
  | RefundStatus,
  { color: string; style: string }
> = {
  authorization_succeeded: {
    color: ColorBlue60,
    style: '',
  },
  capture_succeeded: { color: ColorGreen60, style: '' },
  authorization_declined: {
    color: ColorRed60,
    style: '',
  },
  authorization_failed: {
    color: ColorRed60,
    style: '',
  },
  buyer_approval_required: {
    color: ColorAmber60,
    style: 'pending',
  },
  buyer_approval_pending: {
    color: ColorAmber60,
    style: 'pending',
  },
  capture_pending: {
    color: ColorAmber60,
    style: 'pending',
  },
  declined: {
    color: ColorRed60,
    style: '',
  },
  processing: {
    color: ColorGray70,
    style: 'processing',
  },
  authorization_voided: {
    color: ColorGray70,
    style: '',
  },
  authorization_void_pending: {
    color: ColorGray70,
    style: 'pending',
  },
  active: {
    color: ColorGreen60,
    style: '',
  },
  expired: {
    color: ColorGray70,
    style: '',
  },
  inactive: {
    color: ColorGray70,
    style: '',
  },
  incomplete: {
    color: ColorAmber60,
    style: 'pending',
  },
  created: {
    color: ColorBlue60,
    style: '',
  },
  failed: {
    color: ColorRed60,
    style: '',
  },
  skipped: {
    color: ColorGray70,
    style: '',
  },
  succeeded: { color: ColorGreen60, style: '' },
  voided: {
    color: ColorGray70,
    style: '',
  },
}
