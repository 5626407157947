import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import {
  getReport,
  createReport,
  getReports,
  ReportPayload,
  updateReport,
  ReportsFilters,
  Report,
} from 'reports/services/report'
import { REPORTS } from 'shared/constants/query-keys'
import { useHandleErrorResponse } from 'shared/hooks'
import { CollectionResponse } from 'shared/services/client'

interface CreateReportProps {
  name: string
  schedule?: ReportPayload['schedule']
  scheduleEnabled?: ReportPayload['scheduleEnabled']
  scheduleTimezone: ReportPayload['scheduleTimezone']
  spec: ReportPayload['spec']
}

const useReports = (
  params: Partial<ReportsFilters>,
  options: Partial<UseQueryOptions<CollectionResponse<Report>>>
) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }

  return useQuery({
    ...options,
    queryKey: [REPORTS, merchantAccountId, params],
    queryFn: () => getReports(params),
    meta: {
      error: {
        redirect: true,
      },
    },
  })
}

const useReport = (merchantAccountId: string, id: string) =>
  useQuery({
    queryKey: [REPORTS, id, merchantAccountId],
    queryFn: () => getReport(id),
    meta: {
      error: {
        redirect: true,
      },
    },
  })

const useCreateReport = () => {
  const handleErrorResponse = useHandleErrorResponse()
  return useMutation({
    mutationFn: (data: CreateReportProps) => createReport(data),
    onError: handleErrorResponse,
  })
}

const useUpdateReport = () => {
  const handleErrorResponse = useHandleErrorResponse()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (
      data: Partial<
        Pick<ReportPayload, 'id' | 'name' | 'description' | 'scheduleEnabled'>
      >
    ) => updateReport(data),
    onError: handleErrorResponse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [REPORTS] })
    },
  })
}

export { useCreateReport, useUpdateReport, useReports, useReport }
