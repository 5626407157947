/* eslint-disable camelcase */
import { BadgeProps } from '@gr4vy/poutine-react'
import { TransactionStatus } from 'shared/services/transactions'

export type StatusConfig = {
  label: string
  color: BadgeProps['color']
}

const transactionStatuses: Record<TransactionStatus, StatusConfig> = {
  buyer_approval_pending: {
    label: 'Authenticating',
    color: 'neutral',
  },
  authorization_succeeded: {
    label: 'Authorized',
    color: 'notice',
  },
  capture_succeeded: {
    label: 'Captured',
    color: 'positive',
  },
  capture_pending: {
    label: 'Capturing',
    color: 'neutral',
  },
  authorization_declined: {
    label: 'Declined',
    color: 'negative',
  },
  authorization_failed: {
    label: 'Failed',
    color: 'negative',
  },
  processing: {
    label: 'Processing',
    color: 'neutral',
  },
  authorization_voided: {
    label: 'Voided',
    color: 'positive',
  },
  authorization_void_pending: {
    label: 'Voiding',
    color: 'neutral',
  },
}

const getStatusConfig = (
  mapping: Record<any, StatusConfig | void>,
  status: any
): StatusConfig => {
  return {
    // Fallback values
    label: status,
    color: 'neutral',
    ...mapping[status],
  }
}

export const getTransactionStatusConfigs = (): Record<
  TransactionStatus,
  StatusConfig
> => transactionStatuses

export const getTransactionStatusConfig = (
  status: TransactionStatus
): StatusConfig => {
  return getStatusConfig(transactionStatuses, status)
}
