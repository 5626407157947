import { Text, TextLink, Divider } from '@gr4vy/poutine-react'
import { CredentialsChecker } from 'connections/components/CredentialsChecker'
import { EditGiftCardServiceForm } from 'connections/components/EditGiftCardServiceForm/EditGiftCardServiceForm'
import { useGiftCardServiceAndDefinition } from 'connections/hooks/use-gift-card-service-and-definition'
import { verifyGiftCardServiceCredentials } from 'connections/services/giftCardServices'
import { CredentialField } from 'shared/components/CredentialField/CredentialField'
import { Label } from 'shared/components/Form'
import { Loading } from 'shared/components/Loading'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'

export const GiftCardServiceCredentialsPage = () => {
  const { service, definition, isLoading } = useGiftCardServiceAndDefinition()

  const hasPermission = useResourcePermission(
    Resource.giftCardServices,
    AccessLevel.write
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <EditGiftCardServiceForm submitButtonText="Update credentials">
      <Text margin="none">
        Learn how to configure {definition?.displayName} in{' '}
        <TextLink
          href={`https://docs.gr4vy.com/connections/payments/${definition?.id}`}
          opensInNewWindow
        >
          this quick start guide.
        </TextLink>{' '}
        We will help you find all the information you need to set up your
        payment service correctly.
      </Text>
      {definition?.fields.map((item) => (
        <CredentialField
          key={item.key}
          item={{
            key: item.key,
            displayName: <Label>{item.displayName}</Label>,
          }}
          required={item.required}
          secret={item.secret}
          disabled={!hasPermission}
          group="fields"
        />
      ))}
      {hasPermission && definition?.fields && (
        <CredentialsChecker
          fieldNames={definition.fields.map((field) => ['fields', field.key])}
          verifyCredentials={verifyGiftCardServiceCredentials}
          giftCardServiceDefinitionId={definition?.id}
          giftCardServiceId={service?.id}
        />
      )}
      <Divider />
    </EditGiftCardServiceForm>
  )
}
