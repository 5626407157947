import { Alert, Checkbox, Text } from '@gr4vy/poutine-react'
import { Input } from 'antd'
import { useState } from 'react'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { KountEdit } from './KountEdit'

export const KountOverview = () => {
  const [toggled, setToggled] = useState(false)
  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  return (
    <KountEdit submitButtonText="Update details">
      <Text margin="none">
        Set a custom display name for this connection, in order to find it
        easily within the dashboard. If it&#8217;s deactivated, any flows rules
        using it will no longer be processed.
      </Text>
      <FormItem
        name={['displayName']}
        label={<Label>Display name</Label>}
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input disabled={!hasWritePermission} />
      </FormItem>
      <FormItem
        name={['active']}
        label={<Label>Connection status</Label>}
        valuePropName="checked"
        trigger="onCheckedChange"
        shouldUpdate
      >
        <Checkbox
          disabled={!hasWritePermission}
          onCheckedChange={(e: boolean) => setToggled(e)}
        >
          Use this connection
        </Checkbox>
      </FormItem>
      {toggled && (
        <Alert variant="information">
          <Alert.Icon />
          <Alert.Content>
            <Alert.Title>
              Only 1 anti-fraud connection can be active at a time
            </Alert.Title>
            <Alert.Text>
              Activating this connection will de-activate all other anti-fraud
              connections. Please make sure to update all Flow rules to use
              conditions for this connection before activating.
            </Alert.Text>
          </Alert.Content>
        </Alert>
      )}
    </KountEdit>
  )
}
