import { BadgeProps } from '@gr4vy/poutine-react'
import { startCase } from 'lodash'

export const formatEventName = (event: string) =>
  ({
    [event]: startCase(event),
    'anti-fraud-decision': 'Anti-fraud decision requested',
    'anti-fraud-decision-error': 'Anti-fraud error received',
    'anti-fraud-decision-skipped': 'Anti-fraud decision skipped',
    'anti-fraud-decision-update': 'Anti-fraud decision update sent',
    'anti-fraud-decision-update-error': 'Anti-fraud decision update failed',
    'anti-fraud-transaction-status-update':
      'Anti-fraud transaction update sent',
    'anti-fraud-transaction-status-update-error':
      'Anti-fraud transaction update failed',
    'anti-fraud-webhook': 'Anti-fraud webhook received',
    'digital-wallet-apple-pay-token-decrypted': 'Apple Pay token decrypted',
    'digital-wallet-click-to-pay-token-decrypted':
      'Click to Pay token decrypted',
    'digital-wallet-google-pay-token-decrypted': 'Google Pay token decrypted',
    'bin-lookup-request': 'BIN lookup requested',
    'three-d-secure-authentication-request': 'Card authenticated',
    'three-d-secure-preparation-request': 'Card enrollment checked',
    'three-d-secure-success': 'Card authenticated',
    'three-d-secure-result-request': 'Challenge completed',
    'three-d-secure-request-error': 'Authentication error received',
    'rule-trigger': 'Rule triggered',
    'gift-card-redemption-failed': 'Gift card redemption failed',
    'gift-card-redemption-succeeded': 'Gift card redemption succeeded',
    'gift-card-refund-failed': 'Gift card refund failed',
    'gift-card-refund-succeeded': 'Gift card refund succeeded',
    'gift-card-reversal-succeeded': 'Gift card reversal succeeded',
    'network-token-provision-succeeded': 'Network token provision succeeded',
    'network-token-provision-failed': 'Network token provision failed',
    'network-token-cryptogram-provision-succeeded':
      'Network token cryptogram provision succeeded',
    'network-token-cryptogram-provision-failed':
      'Network token cryptogram provision failed',
    'payment-connector-external-transaction-request': 'API called',
    'payment-connector-response-transaction-declined': 'Authorization declined',
    'payment-connector-response-transaction-authorization-failed':
      'Authorization failed',
    'payment-connector-response-transaction-authorization-succeeded':
      'Authorization succeeded',
    'payment-connector-response-transaction-capture-succeeded':
      'Transaction captured',
    'payment-connector-response-transaction-capture-failed':
      'Transaction capture failed',
    'payment-connector-response-transaction-capture-declined':
      'Transaction capture declined',
    'payment-connector-response-transaction-void-succeeded':
      'Authorization voided',
    'payment-connector-response-transaction-void-failed':
      'Authorization void failed',
    'payment-connector-response-transaction-void-declined':
      'Authorization void declined',
    'the-giving-block-transaction-conversion-succeeded': 'Funds converted',
  })[event]

export const formatSectionName = (key: string) => {
  const acronyms = ['cavv', 'eci', 'id']

  acronyms.forEach((acronym) => {
    const acronymRegex = new RegExp(`\\b${acronym}|${acronym}\\b`, 'i')
    if (acronymRegex.test(key)) {
      key = key.replace(acronymRegex, acronym.toUpperCase())
    }
  })

  return startCase(key)
}

export const getCodeLanguage = (code: string | object) => {
  code = typeof code === 'string' ? code : JSON.stringify(code)

  if (code.match(/^{/)) {
    return 'json'
  }

  if (code.match(/xmlns/g)) {
    return 'xml'
  }

  return undefined
}

export const formatXml = (code: string) => {
  const reg = /(>)(<)(\/*)/g
  const indent = 2
  let pad = 0
  code = code.replace(reg, '$1\n$2$3')

  return code
    .split('\n')
    .map((node) => {
      let indentValue = 0

      if (node.match(/.+<\/\w[^>]*>$/)) {
        indentValue = 0
      } else if (node.match(/^<\/\w/)) {
        if (pad != 0) {
          pad -= indent
        }
      } else if (node.match(/^<\w([^>]*[^\/])?>.*$/)) {
        indentValue = indent
      } else if (node.match(/^<\w.*>$/)) {
        indentValue = indent
      }

      pad += indentValue

      return `${' '.repeat(pad - indentValue)}${node}`
    })
    .join('\n')
}

export const formatJSON = (code: string | object) => {
  code = typeof code === 'object' ? code : JSON.parse(code)
  return JSON.stringify(code, null, 2)
}

export const formatCode = (code: string | object) => {
  if (getCodeLanguage(code) === 'json') {
    return formatJSON(code)
  }

  if (getCodeLanguage(code) === 'xml' && typeof code === 'string') {
    return formatXml(code)
  }

  return String(code)
}

export const getColorByStatusCode = (statusCode: string) => {
  if (statusCode.at(0) === '4') {
    return 'notice'
  }

  if (['2', '3'].includes(statusCode.at(0) || '')) {
    return 'information'
  }

  if (statusCode.at(0) === '5') {
    return 'negative'
  }

  return 'neutral'
}

export const getColorByMethod = (method: string) => {
  method = method.toLowerCase()

  if (method === 'post') {
    return 'information'
  }

  if (method === 'get') {
    return 'positive'
  }

  if (method === 'patch' || method === 'put') {
    return 'data1'
  }

  if (method === 'delete') {
    return 'negative'
  }

  return 'neutral'
}

export const getColorByDecision = (decision: string) =>
  ({
    [decision]: 'neutral',
    ACCEPT: 'positive',
    REVIEW: 'information',
    REJECT: 'notice',
    ERROR: 'negative',
  })[decision] as BadgeProps['color']

export const isCensored = (key?: string): boolean => !!key?.match(/^\**$/)
