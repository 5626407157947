import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  GET_BUYER_TRX,
  TRANSACTION,
} from 'shared/constants/query-keys/transactions'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import {
  showSuccessMessage,
  showProcessingMessage,
  showErrorMessage,
} from 'shared/utils/showMessage'
import { Transaction, voidTransaction } from 'transactions/services'

export const useTransactionVoid = (
  transaction?: Transaction,
  callback?: () => void
) => {
  const queryClient = useQueryClient()
  const invalidateTransactions = async () => {
    await queryClient.invalidateQueries({ queryKey: [GET_BUYER_TRX] })
    await queryClient.invalidateQueries({
      queryKey: [TRANSACTION, transaction?.id],
    })
  }
  const voidMutation = useMutation({
    mutationFn: voidTransaction,
    onSuccess: ({ status }) => {
      if (status === 'authorization_voided') {
        showSuccessMessage('Transaction voided')
        invalidateTransactions()
      } else if (status === 'authorization_void_pending') {
        showProcessingMessage('Transaction void requested')
        invalidateTransactions()
      } else {
        showErrorMessage('Transaction void failed')
      }
    },
    onError: handleErrorResponse,
    onSettled() {
      callback && callback()
    },
  })
  const onVoid = () =>
    transaction?.id ? voidMutation.mutate(transaction.id) : () => {}

  return { onVoid, voiding: voidMutation.isPending }
}
