import { Stack } from '@gr4vy/poutine-react'
import { Dispatch } from 'react'
import AuditLogEntriesTable from 'audit-logs/components/AuditLogEntriesTable'
import { AuditLogEntry } from 'audit-logs/services'
import { PageLayout } from 'shared/components/PageLayout'
import { pathTo } from 'shared/paths/users'
import { QueryResult } from 'shared/services/client'

export interface Filters {
  limit?: number
}

type AuditLogsBrowsePageProps = {
  title: string
  page: QueryResult<AuditLogEntry>
  filters: Filters
  onFilter: Dispatch<Filters>
}

const AuditLogsBrowsePage = ({
  page,
  onFilter,
  filters,
}: AuditLogsBrowsePageProps) => {
  const subNavigation = [
    {
      title: 'All users',
      url: pathTo.users,
      end: true,
    },
    {
      title: 'Activity',
      url: pathTo.usersActivity,
    },
  ]
  return (
    <PageLayout title="Users" subNavigation={subNavigation}>
      <Stack gap={32}>
        <AuditLogEntriesTable page={page} pagination={{ filters, onFilter }} />
      </Stack>
    </PageLayout>
  )
}

export default AuditLogsBrowsePage
