import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  GET_BUYER_TRX,
  LIST_REFUNDS,
  TRANSACTION,
} from 'shared/constants/query-keys/transactions'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import {
  showSuccessMessage,
  showProcessingMessage,
  showErrorMessage,
} from 'shared/utils/showMessage'
import {
  RefundRequest,
  refundTransaction,
  Transaction,
} from 'transactions/services'

export type TransactionRefundProps = Omit<RefundRequest, 'id'>

export const useTransactionRefund = (
  transaction?: Transaction,
  callback?: () => void
) => {
  const queryClient = useQueryClient()
  const invalidateTransactions = async () => {
    await queryClient.invalidateQueries({ queryKey: [GET_BUYER_TRX] })
    await queryClient.invalidateQueries({
      queryKey: [TRANSACTION, transaction?.id],
    })
    await queryClient.invalidateQueries({ queryKey: [LIST_REFUNDS] })
  }

  const refundMutation = useMutation({
    mutationFn: refundTransaction,
    onSuccess: ({ status }) => {
      if (status === 'succeeded') {
        showSuccessMessage('Transaction refunded')
      } else if (status === 'processing') {
        showProcessingMessage('Transaction refund requested')
      } else {
        showErrorMessage('Unable to refund amount')
      }
      invalidateTransactions()
    },
    onError: handleErrorResponse,
    onSettled() {
      callback && callback()
    },
  })

  const onRefund = ({
    amount,
    reason,
    targetType,
    targetId,
  }: TransactionRefundProps) =>
    transaction?.id
      ? refundMutation.mutate({
          id: transaction.id,
          amount,
          targetType,
          targetId,
          ...(reason ? { reason } : {}),
        })
      : () => {}

  return { onRefund, refunding: refundMutation.isPending }
}
